import React from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home, History, Profile } from '../screens';

const router = createBrowserRouter([
	{
		path: "/",
		element: <Home />
	},
	{
		path: "/results",
		element: <History />
	},
	{
		path: "/profile",
		element: <Profile />
	}
]);

export default () => (<RouterProvider router={router} />);