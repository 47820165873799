import React, { useState } from 'react';
import { Button, Form, Container, Content, Columns } from 'react-bulma-components';

export default (props) => {
    const [selectedNoteCount, setSelectedNoteCount] = useState(2);

    const startSession = () => {
        props.onStartSession(selectedNoteCount);
    };

    return (
        <Container>
            <Columns className="is-vcentered" style={{ minHeight: '30vh' }}>
                <Columns.Column size={6} offset={3}>
                    <Content>
                        <h1 className="has-text-centered title is-3">
                            Welcome to Your Coding Session
                        </h1>
                        <p className="has-text-centered subtitle is-5">
                            You are about to embark on a coding journey of testing!
                            Please select the number of tests you would like to start your session with.
                        </p>
                        <div className="content">
                            <ol>
                                <li>
                                    <strong>Diagnosis Code Identification:</strong> Test your knowledge of proper medical coding with five sample charts.
                                </li>
                                <li>
                                    <strong>ICD-10-CM Guidelines:</strong> Apply all appropriate coding conventions as specified by the 2023 ICD-10-CM Guidelines for correct Diagnosis Code(s).
                                </li>
                                <li>
                                    <strong>Procedure Codes:</strong> Identify correct Procedure Code(s), including CPT, Evaluation & Management (E/M), and HCPCS Level II codes.
                                </li>
                                <li>
                                    <strong>Modifiers:</strong> Apply modifier(s) to each Procedure Code as appropriate, based on CMS and AHA coding conventions.
                                </li>
                                <li>
                                    <strong>Data Entry:</strong> You must use correct coding conventions, including a period where applicable for diagnosis codes.
                                </li>
                            </ol>
                        </div>
                        <div className="has-text-centered">
                            <Form.Select
                                onChange={(e) => setSelectedNoteCount(Number(e.target.value))}
                                value={selectedNoteCount}
                                style={{ marginRight: '10px' }}
                                disabled={false}
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => (
                                    <option key={count} value={count}>{count}</option>
                                ))}
                            </Form.Select>
                            <Button onClick={startSession} color="primary">
                                Start Session
                            </Button>
                        </div>
                    </Content>
                </Columns.Column>
            </Columns>
        </Container>
    );
};
