import React, { useState, useEffect } from "react";
import { Form, Button, Columns } from 'react-bulma-components';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from "react-toastify";
import { states } from './data';
import { AppApi } from "../services";

const appApi = new AppApi();
const codingExpertiseOptions = [
    { value: 'Profee Coding', label: 'Profee Coding' },
    { value: 'Facility Coding', label: 'Facility Coding' },
    { value: 'HCC Coding', label: 'HCC Coding' },
    { value: 'CDI', label: 'CDI' },
    { value: 'Auditing', label: 'Auditing' }
];
const codingSpecialtiesOptions = [
    { value: 'Evaluation & Management (E&M)', label: 'Evaluation & Management (E&M)' },
    { value: 'Family Practice/Internal Medicine', label: 'Family Practice/Internal Medicine' },
    { value: 'Ambulatory Surgery Center', label: 'Ambulatory Surgery Center' },
    { value: 'Cardiology', label: 'Cardiology' },
    { value: 'ER', label: 'ER' },
    { value: 'General Surgery', label: 'General Surgery' },
    { value: 'Cardiovascular and Thoracic Surgery', label: 'Cardiovascular and Thoracic Surgery' },
    { value: 'Anesthesiology', label: 'Anesthesiology' },
    { value: 'Ophthalmology', label: 'Ophthalmology' },
    { value: 'Orthopedic Medical', label: 'Orthopedic Medical' },
    { value: 'Orthopedic Surgery', label: 'Orthopedic Surgery' },
    { value: 'Gastroenterology', label: 'Gastroenterology' },
    { value: 'Dermatology', label: 'Dermatology' },
    { value: 'Neurology', label: 'Neurology' },
    { value: 'Wound Care', label: 'Wound Care' },
    { value: 'Pathology', label: 'Pathology' },
    { value: 'Palliative Care', label: 'Palliative Care' },
    { value: 'Physical Therapy', label: 'Physical Therapy' },
    { value: 'Burn Coding', label: 'Burn Coding' },
    { value: 'Radiation Oncology', label: 'Radiation Oncology' },
    { value: 'Hematology/Oncology', label: 'Hematology/Oncology' },
    { value: 'Infectious Disease', label: 'Infectious Disease' }
];

export default ({ initialData = {}, mode = "register", onSubmit }) => {
    const defaultData = {
        experience: '',
        state: '',
        zipcode: '',
        firstName: '',
        lastName: '',
        gender: '',
        certifications: [],
        codingExpertise: [],
        codingSpecialties: [],
        employerType: '',
        otherEmployerType: ''
    };
    const [formData, setFormData] = useState({ ...defaultData, ...initialData });
    const [showOtherEmployerTypeInput, setShowOtherEmployerTypeInput] = useState(false);

    useEffect(() => {
        if (formData.employerType === 'other') {
            setShowOtherEmployerTypeInput(true);
        }
    }, [formData.employerType]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        let newValue = value;
        // check if the changed field is one of the multi-select dropdowns
        if (name === 'codingExpertise' || name === 'codingSpecialties') {
            newValue = Array.from(e.target.selectedOptions, option => option.value);
        }

        setFormData({
            ...formData,
            [name]: newValue
        });

        if (name === 'employerType') {
            setShowOtherEmployerTypeInput(value === 'other');
        }
    };

    const handleSubmit = async (e) => {
        console.log('submit')
        e.preventDefault();

        const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
        if (!zipCodeRegex.test(formData.zipcode)) {
            toast.error("Invalid Zip Code.")
            return;
        }

        if (!formData.state) {
            // Handle empty state
            toast.error("Please select a state.");
            return;
        }

        // Send the data to the API
        const response = await appApi.updateProfile(undefined, formData);
        if (response) {
            // TODO: state change prop
            onSubmit(response)
        } else {
            toast.error("Error saving information, please try again.");
            return;
        };
    }

    const DropdownIndicator = () => null;

    return (
        <Columns className="is-centered">
            <Columns.Column className="is-6">
                <div className="box">
                    {mode === "register" && (
                        <>
                            <h1 className="title is-4 has-text-centered">Welcome to Astute Health!</h1>
                            <p className="has-text-centered">Please provide your demographic information</p>
                        </>
                    )}
                    <form onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Label>Years of Experience</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type="number"
                                    name="experience"
                                    value={formData.experience}
                                    onChange={handleChange}
                                />
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Gender</Form.Label>
                            <Form.Control>
                                <Form.Select name="gender" value={formData.gender} onChange={handleChange}>
                                    <option value="" disabled>Select your gender</option>
                                    <option value="female">Female</option>
                                    <option value="male">Male</option>
                                    <option value="other">Other</option>
                                    <option value="preferNotToSay">Prefer not to say</option>
                                </Form.Select>
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Coding Certifications</Form.Label>
                            <CreatableSelect
                                isMulti
                                name="certifications"
                                placeholder="Enter certifications..."
                                value={formData.certifications.map(val => ({ value: val, label: val }))}
                                onChange={(selected) => {
                                    setFormData({
                                        ...formData,
                                        certifications: selected ? selected.map(s => s.value) : []
                                    });
                                }}
                                isClearable={false}
                                components={{ DropdownIndicator }}
                                onMenuOpen={() => false}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Coding Expertise</Form.Label>
                            <Select
                                isMulti
                                name="codingExpertise"
                                options={codingExpertiseOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(selected) => {
                                    setFormData({
                                        ...formData,
                                        codingExpertise: selected.map(s => s.value)
                                    });
                                }}
                                value={formData.codingExpertise.map(val => ({ value: val, label: val }))}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Coding Specialties</Form.Label>
                            <Select
                                isMulti
                                name="codingSpecialties"
                                options={codingSpecialtiesOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(selected) => {
                                    setFormData({
                                        ...formData,
                                        codingSpecialties: selected.map(s => s.value)
                                    });
                                }}
                                value={formData.codingSpecialties.map(val => ({ value: val, label: val }))}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Employer Type</Form.Label>
                            <Form.Control>
                                <Form.Select name="employerType" value={formData.employerType} onChange={handleChange}>
                                    <option value="" disabled>Select employer type</option>
                                    <option value="hospital">Hospital</option>
                                    <option value="clinic">Clinic</option>
                                    <option value="insuranceCompany">Insurance Company</option>
                                    <option value="other">Other</option>
                                </Form.Select>
                            </Form.Control>
                        </Form.Field>
                        {showOtherEmployerTypeInput && (
                            <Form.Field>
                                <Form.Label>Please specify</Form.Label>
                                <Form.Control>
                                    <Form.Input
                                        type="text"
                                        name="otherEmployerType"
                                        value={formData.otherEmployerType}
                                        onChange={handleChange}
                                        placeholder="Enter other employer type"
                                    />
                                </Form.Control>
                            </Form.Field>
                        )}
                        <Form.Field>
                            <Form.Label>State</Form.Label>
                            <Form.Control>
                                <Form.Select name="state" value={formData.state} onChange={handleChange}>
                                    <option value="" disabled>Select your state</option>
                                    {states.map((state, index) => (
                                        <option key={index} value={state.abbreviation}>{state.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type="text"
                                    name="zipcode"
                                    value={formData.zipcode}
                                    onChange={handleChange}
                                />
                            </Form.Control>
                        </Form.Field>
                        <Button fullwidth color="primary" type="submit">Save</Button>
                    </form>
                </div>
            </Columns.Column>
        </Columns>
    );
};
