import React from 'react'

export default () => (
    <footer className="footer is-primary-bg"
        style={{
            paddingBottom: '40px'
        }}
    >
        <div className="container">
            <div className="columns">

                {/* INFO COLUMN */}
                <div className="column has-text-centered">
                    <div className="content">
                        <h5 className="title is-white-txt is-5" style={{ marginBottom: '8px' }}>
                            Info
                        </h5>
                        <a className="is-white-txt" href="https://app.astute.health/privacy"> Privacy Policy </a> |
                        <a className="is-white-txt" href="https://app.astute.health/terms"> Terms Of Service</a> |
                        {/* CONTACT US LINK */}
                        <a
                            className="is-white-txt"
                            href="https://astute.health/contact-us"
                            target="_blank"  // Open in a new tab
                            rel="noopener noreferrer"  // For security
                        > Contact Us
                        </a>
                        <p style={{ marginTop: '15px' }}> Powered By <strong className="is-white-txt">astute.health</strong> <br />
                            Copyright {new Date().getFullYear()} <br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)